<template>
    <div class="Awarp">
        <dashCard class="powerBox8" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('powerBox8_monitor','频率监测', getZEdata)}}</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <a-space>
                    <div class="type-group">
                        <span class="pick-type" v-for="(item,idx) in timetType" :key="idx" @click="changeType(item.code)">
                            <font :class="{'isactive-type': item.code == searchObj.TYPE}">{{ item.name }}</font>
                        </span>
                    </div>
                    <a-date-picker v-if="searchObj.TYPE=='按天'" :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"/>
                    <!-- <a-week-picker v-if="searchObj.TYPE=='按周'" :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"  v-model="searchObj.VALUE" @change="onChange" /> -->
                    <a-month-picker v-if="searchObj.TYPE=='按月'" :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)" v-model="searchObj.VALUE" @change="onChange" />
                    <a-date-picker v-if="searchObj.TYPE=='按年'" :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"
                    mode="year"
                    :open="panelOpen"
                    @openChange="openChange"
                    @panelChange="panelChange"
                    format="YYYY"
                    />
                </a-space>
            </div>
            <div class="card-content2">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <powerBox8  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'powerBox8',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },

        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                timetType: [
                {
                    code: '按天',
                    name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
                },
                // {
                //     code: '按周',
                //     name:'按周'
                // },
                {
                    code: '按月',
                    name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
                },
                {
                    code: '按年',
                    name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
                }
            ],
            panelOpen: false,
            searchObj: {
                TYPE: '按天',//按天,按周,按月,按年
                VALUE: this.$moment(),//日期
            },
            chartOption1: {},
            detailInfo: {
                chart1: {}
            },
            colorList: ["#51A34B","#B4D0FF","#51A34B"],
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            // 改变类型
            changeType(type) {
                this.searchObj.TYPE = type
                this.getData()
            },
            openChange(status) {
                this.panelOpen = status
            },
            // 年份弹窗关闭
            panelChange(value){
            this.searchObj.VALUE = value;
            this.panelOpen = false;
            this.getData()
            },
            onChange(value) {
                //console.log("onChange",value,this.searchObj)
                this.getData()
            },
            transformSearch: function() {
                var TYPE = this.searchObj.TYPE
                var dateStr = this.searchObj.VALUE.format('YYYY-MM-DD')
                var VALUE = this.$moment(dateStr);
                var monthVALUE = this.$moment(dateStr).startOf('month');
                var obj = {
                    "按天": {
                        startTime: VALUE.format('YYYY-MM-DD 00:00:00'),
                        endTime: VALUE.format('YYYY-MM-DD 23:59:59'),
                    },
                    "按周": {
                        startTime: VALUE.startOf('week').format('YYYY-MM-DD 00:00:00'),
                        endTime: VALUE.endOf('week').format('YYYY-MM-DD 23:59:59'),
                    },
                    "按月": {
                        startTime: monthVALUE.startOf('month').format('YYYY-MM-DD 00:00:00'),
                        endTime: monthVALUE.endOf('month').format('YYYY-MM-DD 23:59:59'),
                    },
                    "按年": {
                        startTime: VALUE.startOf('year').format('YYYY-MM-DD 00:00:00'),
                        endTime: VALUE.endOf('year').format('YYYY-MM-DD 23:59:59'),
                    }
                }
                return obj[TYPE]
            },
            //每月报警预警数
            getData() {
                const url =  Config.dev_url + "/api-apps-v2/api/v2/electric/rate/analysis";
                var data = {
                    equipment:"市电",
                    // startTime:"2022-03-02 00:00:00",
                    // endTime:"2022-03-09 00:00:00"
                }
                Object.assign(data,this.transformSearch())
                this.isLoading = true
                this.$axios.post(url,data).then(res=>{
                    if(res.data.code==0){
                        this.detailInfo = {}
                        var chart1 = {
                            min: 45,
                            max: 55,
                            value: 51.3
                        }
                        chart1.value = res.data.data.Data.avgCount
                    this.detailInfo.chart1 = chart1
                    this.initEchart()



                    }else{
                        // this.$message.warning(res.data.msg)
                    }
                }).catch((wrong)=>{ }).finally(()=>{
                    this.isLoading = false
                })
            },

            getData0() {
                this.detailInfo = {}
                var chart1 = {
                    min: 45,
                    max: 55,
                    value: 51.3
                }
                this.detailInfo.chart1 = chart1
                this.initEchart()
            },
            initEchart() {
                var vm = this
                var {min,max,value} = this.detailInfo.chart1

                var option = {
                    animation:false,
                    layoutAnimation:false,
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        // 背景
                        {
                            animation: false,
                            name: vm.commonLangHandler('powerBox12_monitor','功率监测', vm.getZEdata),
                            radius:'100%',
                            type: 'gauge',
                            center: ['50%', '50%'],
                            splitNumber: 12,
                            // 展示当前进度
                            progress: {
                                show: true,
                                width: 8,
                                itemStyle: {
                                    color: '#DBDBDB',
                                },
                            },
                            // 仪表盘指针
                            pointer: {
                                show: false,
                            },
                            // 仪表盘轴线相关配置
                            axisLine: {
                                show: false,
                            },
                            // 刻度样式
                            axisTick: {
                                show: false,
                            },
                            // 分隔线样式
                            splitLine: {
                                show: false,
                            },
                            // 刻度标签
                            axisLabel: {
                                show: false,
                            },
                            // 表盘中指针的固定点
                            anchor: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: 100,
                                },
                            ],
                        },
                        // 内容
                        {
                            name: '',
                            type: 'gauge',
                            radius:'100%',
                            center: ['50%', '50%'],
                            min: min,
                            max: max,
                            zlevel: 10,
                            splitNumber: 12,
                            // 展示当前进度
                            progress: {
                                show: true,
                                width: 8,
                                itemStyle: {
                                    color: '#51A34B',
                                },
                            },
                            // 仪表盘指针
                            pointer: {
                                show: false,
                            },
                            // 仪表盘轴线相关配置
                            axisLine: {
                                show: false,
                            },
                            // 刻度样式
                            axisTick: {
                                show: false,
                            },
                            // 分隔线样式
                            splitLine: {
                                show: false,
                            },
                            splitNumber: 1,
                            // 刻度标签
                            axisLabel: {
                                show: true,
                                padding: [0, 10, 0, 10],
                                distance: -30,
                            },
                            // 表盘中指针的固定点
                            anchor: {
                                show: false,
                            },
                            detail: {
                                borderRadius: 8,
                                offsetCenter: ['0%', '-15%'],
                                fontSize: 30,
                                fontWeight: 'bolder',
                                formatter: '{value}',
                                color: 'rgba(0,0,0,.65)',
                            },
                            data: [
                                {
                                    value: value,
                                    name: this.LangHandler('frequency','频率(Hz)')
                                },
                            ],
                        },
                    ],
                };

                this.updateChart('chart1', 'chartOption1', option)
            },
            updateChart: function (refName, optionName, option = {}) {
                /* 渲染echart图 */
                if (!optionName) return
                this[optionName] = option
                setTimeout(() => {
                    this.$refs[refName].updateChartView()
                }, 500)
            },

        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>

<style lang="less" scoped>
.powerBox8 {
    .card-content1{
        height: 68px;
        width: 100%;
        .type-group{
            width: 100%;
        }
    }
    .card-content2 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
}
</style>
